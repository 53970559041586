<template>
    <div class="enterpriseStyle">
        <div class="banner">
            <img src="../assets/images/firm/banner3.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/">联盟风采   </a>
            </div>
        </div>
        <div class="firm">
            <div class="firm-content">
                <div class="content">
                    <div class="item-box" v-for="(item,index) in firmList" :key="index" @click="godetail(item)">
                    <img :src="imgurl+item.img" alt="" srcset="" width="100%">
                    <div class="title">{{ item.gsmc }}</div>
                </div>
                </div>
            </div>
        </div>
        <div class="pages">
            <el-pagination
                :page-size="6"
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="changesome"
                >
            </el-pagination>
       </div>


    </div>
</template>

<script>
	import request from "../utils/request.js";
		  import { apiUrl } from '@/config'
	import Swiper from "swiper";
	import { setTimeout } from "timers";
export default {
    data(){
        return {
			imgurl:apiUrl,
			queryId:596,
			total:0,
			pageNum:1,
			
                current:1,
                firmList:[
                ]
        }
    },
    methods:{
        changesome(current){
           var that = this;
           that.pageNum = current;
           that.getQyfc()
        },
        godetail(item){
          this.$router.push({path:"enterpriseStyledeatail/"+this.queryId+"/"+item.id})
        },
		/**企业风采列表**/
		getQyfc(){
			var that=this
			var parm=
			{
			   "groupval": "",
			   "pageSize": 10,
			   "pageNum": 1,
			   "orderByColumn": "id",
			   "isAsc": "desc",
			   "formId": 825,
			   "xssx": "",
			   "queryId": "596",
			   "queryStr": "",
			   "isId": 0
			}
					
			request.post('/spms/previewQuery/list',parm).then(res =>{
				if(res.code==200){
					const {data}=res
					data.rows.forEach(item =>{
						if(item.tpDepotCompressUrl && item.tpDepotCompressUrl.length>0){
							item.img=item.tpDepotCompressUrl[0]
						}
						
					})
					that.firmList=data.rows;
					that.firmList=data.rows;
					that.total=data.total
				}
			})
		}
    },
    mounted(){
		var that=this
		 that.getQyfc()
	}
}
</script>
<style lang="scss" scoped>
.enterpriseStyle{
    box-sizing: border-box;
    .banner{
        width: 100%;
        height: 300px;
        .bimg{
            width: 100%;
            height: 100%;
        }
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        .orientation-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 60px;
            color:#666666
        }
    }
    .firm{
        margin-top: 30px;
        .firm-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            border-bottom: 1px solid #e5e5e5;
            .content{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                
            .item-box{
                width: 30%;
                background-color: #f5f5f5;
                margin-bottom: 35px;
                box-sizing: border-box;
                .title{
                    line-height: 60px;
                    text-align: center;
                    font-size: 15px;
                    color: #333333;
                    font-weight: bold;
                }
                .title:hover{
                    background-color: #185bb4;
                    color: white;
                }
            }
            }


        }
    }
    // 分页
    .pages{
        margin:40px 0;
        display:flex;
        justify-content: center;
    }
}

</style>
